export default [
  ///REPORTS

  {
    path: '/accounting/reports',
    name: 'reports',
    component: () => import('@/views/accounting/Reports.vue'),
    meta: {
      Permission: 'view_business_overview',
    },
  },

  {
    path: '/accounting/reports/inventory',
    name: 'report-inventory',
    component: () => import('@/views/accounting/InventoryReport.vue'),
    meta: {
      Permission: 'view_sale_report',
    },
  },

  {
    path: '/accounting/reports/stock',
    name: 'report-stock',
    component: () => import('@/views/accounting/InventoryStockReport.vue'),
    meta: {
      Permission: 'view_sale_report',
    },
  },

  {
    path: '/accounting/reports/profitloss',
    name: 'report-profitloss',
    component: () => import('@/views/accounting/ProfitLoss.vue'),
    meta: {
      Permission: 'view_profit_loss',
    },
  },

  {
    path: '/accounting/reports/journal',
    name: 'report-journal',
    component: () => import('@/views/accounting/GeneralJournal.vue'),
    meta: {
      Permission: 'view_profit_loss',
    },
  },

  
  {
    path: '/accounting/accounts',
    name: 'account-list',
    component: () => import('@/views/accounting/AccountList.vue'),
    meta: {
      Permission: 'view_profit_loss',
    },
  },
  

  {
    path: '/accounting/add-account',
    name: 'account-add',
    component: () => import('@/views/accounting/AddAccount.vue'),
    meta: {
      Permission: 'view_profit_loss',
    },
  },

  {
    path: '/accounting/journal-entry',
    name: 'journal-entry',
    component: () => import('@/views/accounting/AddJournalEntry.vue'),
    meta: {
      Permission: 'view_profit_loss',
    },
  },


  {
    path: '/accounting/journal-preview/:id',
    name: 'journal-preview',

    component: () => import('@/views/accounting/EditJournalEntry.vue'),
    meta: {
      Permission: 'view_profit_loss',
    },
  },

  {
    path: '/accounting/account-preview/:id',
    name: 'account-preview',

    component: () => import('@/views/accounting/EditAccount.vue'),
    meta: {
      Permission: 'view_profit_loss',
    },
  },




];

export default [
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/settings/Settings.vue'),
    meta: {
      resource: 'settings',
      action: 'read',
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/customer',
    name: 'settings-customer',
    component: () => import('@/views/settings/SetCustomer/SetCustomerPage.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/customer/preview/:id',
    name: 'settings-customer-detail',
    component: () => import('@/views/settings/SetCustomer/SetCustomerPageDetail.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/customer/create',
    name: 'settings-customer-create',
    component: () => import('@/views/settings/SetCustomer/SetCustomerPageCreate.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/inventory',
    name: 'settings-inventory',
    component: () => import('@/views/settings/SetInventory/SetInventoryPage.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/inventory/preview/:id',
    name: 'settings-inventory-detail',
    component: () => import('@/views/settings/SetInventory/SetInventoryPageDetail.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/inventory/create',
    name: 'settings-inventory-create',
    component: () => import('@/views/settings/SetInventory/SetInventoryPageCreate.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/sale',
    name: 'settings-sale',
    component: () => import('@/views/settings/SetSale/SetSalePage.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/sale/preview/:id',
    name: 'settings-sale-detail',
    component: () => import('@/views/settings/SetSale/SetSalePageDetail.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/sale/create',
    name: 'settings-sale-create',
    component: () => import('@/views/settings/SetSale/SetSalePageCreate.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/purchase',
    name: 'settings-purchase',
    component: () => import('@/views/settings/SetPurchase/SetPurchasePage.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },
  {
    path: '/settings/purchase/preview/:id',
    name: 'settings-purchase-detail',
    component: () => import('@/views/settings/SetPurchase/SetPurchasePageDetail.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/purchase/create',
    name: 'settings-purchase-create',
    component: () => import('@/views/settings/SetPurchase/SetPurchasePageCreate.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/order',
    name: 'settings-order',
    component: () => import('@/views/settings/SetOrder/SettingsOrder.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/order/purchaseorder/create',
    name: 'settings-purchaseorder-create',
    component: () => import('@/views/settings/SetOrder/SetPurchaseOrder/SetPurchaseOrderPageCreate.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/order/purchaseorder',
    name: 'settings-purchaseorder',
    component: () => import('@/views/settings/SetOrder/SetPurchaseOrder/SetPurchaseOrderPage.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/order/purchaseorder/preview/:id',
    name: 'settings-purchaseorder-detail',
    component: () => import('@/views/settings/SetOrder/SetPurchaseOrder/SetPurchaseOrderPageDetail.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/order/saleorder/create',
    name: 'settings-saleorder-create',
    component: () => import('@/views/settings/SetOrder/SetSaleOrder/SetSaleOrderPageCreate.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/order/saleorder',
    name: 'settings-saleorder',
    component: () => import('@/views/settings/SetOrder/SetSaleOrder/SetSaleOrderPage.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/order/saleorder/preview/:id',
    name: 'settings-saleorder-detail',
    component: () => import('@/views/settings/SetOrder/SetSaleOrder/SetSaleOrderPageDetail.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/order/adjustment/create',
    name: 'settings-adjustment-create',
    component: () => import('@/views/settings/SetOrder/SetAdjustment/SetAdjustmentPageCreate.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/order/adjustment',
    name: 'settings-adjustment',
    component: () => import('@/views/settings/SetOrder/SetAdjustment/SetAdjustmentPage.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/order/adjustment/preview/:id',
    name: 'settings-adjustment-detail',
    component: () => import('@/views/settings/SetOrder/SetAdjustment/SetAdjustmentPageDetail.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/accounting',
    name: 'settings-accounting',
    component: () => import('@/views/settings/SetAccounting/SettingsAccounting.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/accounting/config',
    name: 'settings-config',
    component: () => import('@/views/settings/SetAccounting/SetAccountingConfig/SetConfig.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/accounting/inventoryreport/create',
    name: 'settings-inventoryreport-create',
    component: () => import('@/views/settings/SetAccounting/SetInventoryReport/SetInventoryReportPageCreate.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/accounting/inventoryreport',
    name: 'settings-inventoryreport',
    component: () => import('@/views/settings/SetAccounting/SetInventoryReport/SetInventoryReportPage.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/accounting/inventoryreport/preview/:id',
    name: 'settings-inventoryreport-detail',
    component: () => import('@/views/settings/SetAccounting/SetInventoryReport/SetInventoryReportPageDetail.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/accounting/stockreport/create',
    name: 'settings-stockreport-create',
    component: () => import('@/views/settings/SetAccounting/SetStockReport/SetStockReportPageCreate.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/accounting/stockreport',
    name: 'settings-stockreport',
    component: () => import('@/views/settings/SetAccounting/SetStockReport/SetStockReportPage.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/accounting/stockreport/preview/:id',
    name: 'settings-stockreport-detail',
    component: () => import('@/views/settings/SetAccounting/SetStockReport/SetStockReportPageDetail.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/dropdown',
    name: 'settings-dropdown',
    component: () => import('@/views/settings/DropDown/DropDown.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/userrole',
    name: 'settings-userrole',
    component: () => import('@/views/settings/Permissions/role-list/RoleList.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/general',
    name: 'settings-general',
    component: () => import('@/views/settings/General/General.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/importExcelInventory',
    name: 'settings-importExcelInventory',
    component: () => import('@/views/settings/ImportExcel/ImportInventoryExcel'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/importExcelsMenu',
    name: 'settings-importExcelsMenu',
    component: () => import('@/views/settings/ImportExcel/ImportExcelsMenu'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/importExcel',
    name: 'settings-importExcel',
    component: () => import('@/views/settings/ImportExcel/ImportExcel'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/importExcelPurchase',
    name: 'settings-importExcelPurchase',
    component: () => import('@/views/settings/ImportExcel/ImportPurchaseExcel'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/importExcelSale',
    name: 'settings-importExcelSale',
    component: () => import('@/views/settings/ImportExcel/ImportSaleExcel'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/importExcelPurchaseOrder',
    name: 'settings-importExcelPurchaseOrder',
    component: () => import('@/views/settings/ImportExcel/ImportExcelPurchaseOrder'),
    meta: {
      Permission: 'no_permission_required',
    },
  },
  {
    path: '/settings/importExcelSaleOrder',
    name: 'settings-importExcelSaleOrder',
    component: () => import('@/views/settings/ImportExcel/ImportExcelSaleOrder'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/importExcelAdjustment',
    name: 'settings-importExcelAdjustment',
    component: () => import('@/views/settings/ImportExcel/ImportExcelAdjustment'),
    meta: {
      Permission: 'no_permission_required',
    },
  },
  {
    path: '/settings/importExcelCustomer',
    name: 'settings-importExcelCustomer',
    component: () => import('@/views/settings/ImportExcel/ImportCustomerExcel'),
    meta: {
      Permission: 'no_permission_required',
    },
  },
  {
    path: '/settings/setRequiredInventory',
    name: 'settings-setRequiredInventory',
    component: () => import('@/views/settings/RequiredInputs/SetRequiredInventory'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/lead',
    name: 'settings-lead',
    component: () => import('@/views/settings/SetLead/SetLeadPage.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },
  {
    path: '/settings/lead/preview/:id',
    name: 'settings-lead-detail',
    component: () => import('@/views/settings/SetLead/SetLeadPageDetail.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/lead/create',
    name: 'settings-lead-create',
    component: () => import('@/views/settings/SetLead/SetLeadPageCreate.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },




  {
    path: '/settings/inception',
    name: 'settings-inception',
    component: () => import('@/views/settings/SetInception/SetInceptionPage.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },
  {
    path: '/settings/inception/preview/:id',
    name: 'settings-inception-detail',
    component: () => import('@/views/settings/SetInception/SetInceptionPageDetail.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/inception/create',
    name: 'settings-inception-create',
    component: () => import('@/views/settings/SetInception/SetInceptionPageCreate.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },



  {
    path: '/settings/offroad',
    name: 'settings-offroad',
    component: () => import('@/views/settings/SetOffroad/SetOffroadPage.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },
  {
    path: '/settings/offroad/preview/:id',
    name: 'settings-offroad-detail',
    component: () => import('@/views/settings/SetOffroad/SetOffroadPageDetail.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/offroad/create',
    name: 'settings-offroad-create',
    component: () => import('@/views/settings/SetOffroad/SetOffroadPageCreate.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },


];
